const localBusiness = {
  namespaced: true,
  state: {
    agentName: '',
    agentCode: '',
    carName: '',
  },
  getters: {},
  mutations: {
    //存储token方法
    //设置token等于外部传递进来的值
    setToken (state, token) {
      localStorage.setItem(token.token + 'token', JSON.stringify({
        agentName: token.agentName,
        agentCode: token.agentCode,
        carName: token.carName,
        time: token.time
      }))
      state.agentName = token.agentName
      state.agentCode = token.agentCode
      state.carName = token.carName
    }
  },
  actions: {}
}
export default localBusiness
