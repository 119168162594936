import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import localBusiness from '@/store/module/localbusiness'

export default createStore({
  state: {
    token: ''
  },
  getters: {},
  mutations: {
    setToken (state, token) {
      state.token = token.token
    }
  },
  actions: {},
  modules: {
    localBusiness
  },
  plugins: [createPersistedState({
    storage: window.localStorage
  })],
})
