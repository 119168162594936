import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import '@/assets/js/reset.js'
import {
  CountDown,
  Progress,
  Lazyload,
  Area,
  DropdownMenu,
  DropdownItem,
  Checkbox,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  DatetimePicker,
  RadioGroup,
  Radio
} from 'vant'
import scroll from 'vue-seamless-scroll/src'
import VueClipboard from 'vue-clipboard2'
import 'vant/lib/index.css'
import '@/assets/icon/iconfont.css'
import popup from '@/utils/popup.js'
import '@/utils/sessiontoken'
import { setPvUv } from '@/api/pvuvapi'
import wx from 'weixin-js-sdk'
import '@/assets/css/aqy/media.css'
import '@/assets/css/aqy/popup.css'
import '@/assets/css/aqy/style.css'

let app = createApp(App)
app.use(CountDown).use(Progress).use(Lazyload, {
  lazyComponent: true,
}).use(Area).use(DropdownMenu).use(DropdownItem).use(Checkbox).use(Swipe).use(SwipeItem).use(Tab).use(Tabs).use(DatetimePicker)
  .use(RadioGroup).use(Radio)
app.config.globalProperties.$setPvUv = setPvUv
app.config.globalProperties.$wx = wx

app.use(scroll)
app.use(VueClipboard)
app.use(popup).use(store).use(router).mount('#app')
