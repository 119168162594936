//判断登录有效期
export function getLocalStorageAndTime (exp = 60 * 60 * 1000) {
  let vuex = JSON.parse(localStorage.getItem('vuex'))
  if (!vuex) return false
  // 获取数据
  let data = localStorage.getItem(vuex.token + 'token')
  if (!data) return false
  let dataObj = JSON.parse(data)
  // 与过期时间比较
  if (new Date().getTime() - dataObj.time > exp) {
    // 过期删除返回null
    localStorage.removeItem(vuex.token + 'token')
    return false
  } else {
    return true
  }
}
