import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { getLocalStorageAndTime } from '@/utils/token'

const routes = [
  {
    path: '/',
    redirect: '/404',
  },
  /**爱奇艺流量推广包 */
  {
    path: '/aqy/aqy',
    name: 'aqyllb',
    component: () => import('@/views/aqy/aqy'),
    meta: {
      title: '爱奇艺'
    }
  },
  /**
   * 飞young公众号签到活动
   */
  //飞young公众号签到活动
  {
    path: '/activity/sign',
    name: 'Sign',
    component: () => import('@/views/activity/Sign'),
    meta: {
      title: '签到'
    }
  },
  {
    path: '/activity/signactivity',
    name: 'SignActivity',
    component: () => import('@/views/activity/SignActivity'),
    meta: {
      title: '签到首页'
    }
  },

  /**
   * 自己接收表单号卡
   */
  //翼支付小红书联名女王卡
  {
    path: '/cardpage/queencard',
    name: 'QueenCard',
    component: () => import('@/views/cardpage/queencard/QueenCard'),
    meta: {
      title: '小红书女王卡',
    }
  },
  //翼支付小红书百商联名卡
  {
    path: '/cardpage/businesscard',
    name: 'BusinessCard',
    component: () => import('@/views/cardpage/businesscard/BusinessCard'),
    meta: {
      title: '百商联名卡',
    }
  },
  //长电红卡38套餐
  /* {
     path: '/cdredcard/cdredcard',
     name: 'CDRedCard38',
     component: () => import('@/views/cardpage/cdredcard38/CDRedCard'),
     meta: {
       title: '红卡38套餐',
     }
   },*/

  /**
   * 腾讯视频抽奖活动路由
   */
  {
    path: '/txcjhd/home',
    name: 'TXHome',
    component: () => import('@/views/txcjhd/202205/Home'),
    meta: {
      title: '抽奖活动'
    }
  }, {
    path: '/txcjhd/gameprize',
    name: 'GamePrize',
    component: () => import('@/views/txcjhd/202205/GamePrize'),
    meta: {
      title: '游戏礼包领取'
    }
  }, {
    path: '/txcjhd/memberprize',
    name: 'MemberPrize',
    component: () => import('@/views/txcjhd/202205/MemberPrize'),
    meta: {
      title: '会员权益领取'
    }
  }, {
    path: '/txcjhd/entityprize',
    name: 'EntityPrize',
    component: () => import('@/views/txcjhd/202205/EntityPrize'),
    meta: {
      title: '实物奖品领取'
    }
  }, {
    path: '/txcjhd/entityform',
    name: 'EntityForm',
    component: () => import('@/views/txcjhd/202205/EntityForm'),
    meta: {
      title: '实物奖品信息提交'
    }
  },

  /**
   * 对接电信号卡
   */
  {
    path: '/dxcardpage/xhs/cardpage',
    name: 'XHSCardPage',
    component: () => import('@/views/dxcardpage/xhs/CardPage'),
    meta: {
      title: '小红书59女神卡'
    }
  },

  /**
   * 湖南电信互联网存量拉新福利领取
   */
  {
    path: '/hndxcl/lx1/getprize1',
    name: 'LXGetPrize1',
    component: () => import('@/views/hndxcl/lx1/getPrize1'),
    meta: {
      title: '实物领取'
    }
  },
  {
    path: '/hndxcl/lx1/getprize2',
    name: 'LXGetPrize2',
    component: () => import('@/views/hndxcl/lx1/getPrize2'),
    meta: {
      title: '虚拟领取'
    }
  }, {
    path: '/hndxcl/lx1/getprize3',
    name: 'LXGetPrize3',
    component: () => import('@/views/hndxcl/lx1/getPrize3'),
    meta: {
      title: '流量领取'
    }
  },
  /**
   * 湖南电信与小红书福利领取拉新
   */
  {
    path: '/hndxcl/lx2/home',
    name: 'LX2Home',
    component: () => import('@/views/hndxcl/lx2/Home'),
    meta: {
      title: '福利活动首页'
    }
  }, {
    path: '/hndxcl/lx2/getprize',
    name: 'LX2GPrize',
    component: () => import('@/views/hndxcl/lx2/getPrize'),
    meta: {
      title: '领取福利'
    }
  },

  /**
   * 公司卡密兑换H5页面
   */
  {
    path: '/xgykm/home',
    name: 'XGYKMHome',
    component: () => import('@/views/xgykm/Home'),
    meta: {
      title: '卡密兑换'
    }
  }, {
    path: '/xgykm/ewm',
    name: 'XGYKMEWM',
    component: () => import('@/views/xgykm/EWM'),
    meta: {
      title: '二维码'
    }
  }, {
    path: '/xgykm/merchantorder',
    name: 'XGYKMMOrder',
    component: () => import('@/views/xgykm/MerchantOrder'),
    meta: {
      title: '商家订单页面'
    }
  },

  /**
   * 本地商户核销系统
   */
  {
    path: '/localbusiness/home',
    name: 'LBHome',
    component: () => import('@/views/localbusiness/Home'),
    meta: {
      title: '商户核销管理页面',
      requireAuth: true,
      version: 'localBusiness'
    }
  },
  {
    path: '/localbusiness/history',
    name: 'LocalBusinessHistory',
    component: () => import('@/views/localbusiness/LocalBusinessHistory'),
    meta: {
      title: '历史订单页面',
      requireAuth: true,
      version: 'localBusiness',
    }
  },
  {
    path: '/localbusiness/login',
    name: 'LBLogin',
    component: () => import('@/views/localbusiness/Login'),
    meta: {
      title: '登录',
    }
  },

  {
    path: '/ewm',
    name: 'EWM',
    component: () => import('@/components/EWM'),
    meta: {
      title: '生成二维码'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
]
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  history: createWebHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes,
})
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // next()
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (getLocalStorageAndTime()) {
      next()
    } else {
      if (to.meta.version == 'localBusiness') {
        next('/localbusiness/login')
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
